import { initializeApp } from "firebase/app";
import {getAuth,GoogleAuthProvider} from "firebase/auth";
import { getDatabase } from "firebase/database";





// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBY9sbSwRUQyVP39M8j_TrDDt_lv-2PzYs",
  authDomain: "mycreditcardupselling.firebaseapp.com",
  databaseURL: "https://mycreditcardupselling-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "mycreditcardupselling",
  storageBucket: "mycreditcardupselling.appspot.com",
  messagingSenderId: "1003771665117",
  appId: "1:1003771665117:web:4dd39b99088d14fdd45934"
};

// Initialize Firebase
// Import the functions you need from the SDKs you need


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app)
const provider = new GoogleAuthProvider();
const database = getDatabase(app);
export { auth, provider, database };










