

const API_BASE_URL = "https://api.example.com";



// Define your individual endpoints as constants or variables
const SIGNUP_ENDPOINT = "/signup";
const LOGIN_ENDPOINT = "/login";
const USER_PROFILE_ENDPOINT = "/profile";
const POSTS_ENDPOINT = "/posts";
// ... Add more endpoints as needed ...

// Export the endpoints to use them in other files
export const apiEndpoints = {
  signup: API_BASE_URL + SIGNUP_ENDPOINT,
  login: API_BASE_URL + LOGIN_ENDPOINT,
  userProfile: API_BASE_URL + USER_PROFILE_ENDPOINT,
  posts: API_BASE_URL + POSTS_ENDPOINT,
  // ... Export more endpoints as needed ...
};

export const zet_API_Url = "https://api.myteams.co.in/sso-login";
export const zet_API_Key = "wEWhEi8DO281jfxEpOS8gOwPyUsET9oD";
export const zet_Response_Url = "https://myteams.co.in/?hash=";